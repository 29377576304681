import React, { useState, useRef, useEffect } from "react";
import AnimatedCursor from "react-animated-cursor"
import './Home.css';
import FreeDrawing from "./FreeDrawing";

function useInterval(callback, delay) {
    const savedCallback = useRef();
  
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
  
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

function Home() {
    const [mouseDown, setMouseDown] = useState(false);
    /*
    const [mousePosition, setMousePosition] = React.useState({ x: null, y: null });
    useEffect(() => {
        const updateMousePosition = ev => {
          setMousePosition({ x: ev.clientX, y: ev.clientY });
        };
        window.addEventListener('mousemove', updateMousePosition);
        return () => {
          window.removeEventListener('mousemove', updateMousePosition);
        };
      }, []);
    */
    
    const onMouseDown = () => {
        setMouseDown(true);
    }
    const onMouseUp = () => {
        setMouseDown(false);
    }
    
    useInterval(onMouseDown, mouseDown ? 100 : null);

    
    
    return (
        <div className="Home" onMouseDown={onMouseDown} onMouseUp={onMouseUp} onTouchStart={onMouseDown} onTouchEnd={onMouseUp}>
            {!mouseDown && <h1 style={{position: 'fixed', zIndex: 1}}>draw</h1>}
            <AnimatedCursor
                innerSize={8}
                outerSize={35}
                innerScale={1}
                outerScale={2}
                outerAlpha={0}
                hasBlendMode={true}
                innerStyle={{
                    backgroundColor: 'var(--cursor-color)'
                }}
                outerStyle={{
                    border: '3px solid var(--cursor-color)'
                }}
            />
            <FreeDrawing />
        </div>
    );
}

export default Home;